@import './normalize';
@import './variables';
@import './fonts';
@import './header';
@import './footer';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #333;
  color: 'red';
  font-family: 'TT Firs Neue', sans-serif;
}

.main {
  padding-top: 70px;
}

.container {
  max-width: 1520px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  width: 100%;
}

.page-hero {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media all and (max-width: 1520px) {
      padding-right: 0;
    }
    @media all and (max-width: 992px) {
      padding-right: 15px;
    }
  }
  &__content {
    padding: 50px 0;
    padding-right: 50px;
    @media all and (max-width: 992px) {
      padding-right: 0;
    }
    &__title {
      font-size: 64px;
      font-weight: 500;
      margin: 0;
      color: #fff;
      @media all and (max-width: 768px) {
        font-size: 42px;
      }
    }
    &__lead {
      margin-top: 20px;
      font-size: 18px;
      color: #fff;
    }
  }
  &__media {
    height: 450px;
    min-width: 630px;
    width: 630px;
    @media all and (max-width: 1200px) {
      min-width: 50%;
      width: 50%;
    }
    @media all and (max-width: 992px) {
      display: none;
    }
  }
}

.page-main {
  padding-top: 50px;
  padding-bottom: 50px;
}

.companies-page-wrapper {
  margin-top: 50px;
  .companies__item__lead {
    display: none;
  }
  .inner-page-section__media {
    background: url('../images/firmenniy-magazin.jpeg') no-repeat 50% 50% / cover;
  }
}

.inner-page-section--green {
  .inner-page-section__media {
    background: url('../images/kollektiv.jpeg') no-repeat 50% 50% / cover;
  }
}

.breadcrumbs {
  display: flex;
  &__item {
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-right: 5px;
    color: $primary;
    &--link {
      &:hover {
        text-decoration: underline;
      }
    }
    &--home {
      color: #B2B2B2;
      &:after {
        content: '>';
        margin-left: 5px;
      }
    }
  }
}

.companies {
  &__lead {
    margin-top: 20px;
    font-size: 16px;
  }
  &__list {
    margin-top: 50px;
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2,1fr);
    @media all and (max-width: 768px) {
      grid-template-columns: repeat(2,1fr);
    }
    @media all and (max-width: 640px) {
      grid-template-columns: repeat(1,1fr);
    }
  }
  &__item {
    &__media {
      height: 250px;
      border-radius: 20px;
      width: 100%;
    }
    &__title {
      margin-top: 14px;
      font-size: 20px;
      color: $primary;
      font-weight: 500;
    }
    &__description,
    &__lead {
      margin-top: 8px;
      font-weight: 300;
    }
  }
}

.about-page {
  &__mission {
    background-color: #CCEAFF;
    margin-top: 100px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 992px) {
      margin-top: 50px;
    }
    @media all and (max-width: 768px) {
      margin-top: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &__media {
      text-align: right;
      width: 50%;
      min-width: 50%;
      margin-left: 50px;
      @media all and (max-width: 768px) {
        display: none;
      }
      &__img {
        max-width: 100%;
      }
    }
    &__content {
      &__title {
        font-size: 26px;
        font-weight: 500;
        color: $primary;
      }
      &__paragraph {
        margin-top: 20px;
        color: #222;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

.inner-page-section {
  margin-top: 100px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media all and (max-width: 992px) {
    margin-top: 50px;
  }
  @media all and (max-width: 768px) {
    margin-top: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &--green {
    background-color: $primary-light;
  }
  &--white {
    @media all and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
    .inner-page-section__media {
      background: none;
      min-width: 50%;
      width: 50%;
      text-align: left;
      height: auto;
    }
    .inner-page-section__media img {
      max-width: 100%;
    }
  }
  &__media {
    background-color: #ccc;
    width: 30%;
    min-width: 30%;
    height: 250px;
    margin-right: 50px;
    @media all and (max-width: 768px) {
      display: none;
    }
    &__img {
      max-width: 100%;
    }
  }
  &__content {
    &__title {
      font-size: 26px;
      font-weight: 500;
      color: $primary;
    }
    &__paragraph {
      margin-top: 20px;
      color: #222;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.sustainability {
  &__intro {
    display: flex;
    align-items: center;
    margin-top: 100px;
    @media all and (max-width: 992px) {
      flex-direction: column;
      margin-top: 50px;
    }
    @media all and (max-width: 768px) {
      margin-top: 25px;
    }
    &__video-frame {
      width: 50%;
      min-width: 50%;
      @media all and (min-width: 992px) {
        padding-right: 20px;
      }
      @media all and (max-width: 992px) {
        width: 100%;
        margin-bottom: 15px;
      }
      &__player {
        width: 100%;
      }
    }
    &__content {
      @media all and (min-width: 992px) {
        padding-left: 20px;
      }
      &__text {
        font-size: 20px;
        line-height: 28px;
        @media all and (max-width: 640px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
  &__aims {
    display: grid;
    gap: 36px;
    grid-template-columns: repeat(3,1fr);
    margin-top: 100px;
    @media all and (max-width: 992px) {
      margin-top: 50px;
      gap: 16px;
    }
    @media all and (max-width: 768px) {
      margin-top: 25px;
      grid-template-columns: repeat(2,1fr);
      gap: 32px;
    }
    @media all and (max-width: 640px) {
      grid-template-columns: repeat(1,1fr);
      gap: 16px;
    }
    &__item {
      background-color: #FBFBFB;
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
      border-radius: 8px;
      padding: 16px;
      &__media {
        height: 100px;
      }
      &__content {
        &__title {
          color: $primary;
          font-size: 22px;
          font-weight: 500;
        }
        &__text {
          margin-top: 10px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  &__partners {
    margin-top: 100px;
    @media all and (max-width: 992px) {
      flex-direction: column;
      margin-top: 50px;
    }
    @media all and (max-width: 768px) {
      margin-top: 25px;
    }
    &__title {
      font-size: 26px;
      font-weight: 500;
      color: $primary;
    }
    &__list {
      margin-top: 50px;
      display: grid;
      gap: 36px;
      grid-template-columns: repeat(3,1fr);
      @media all and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
        gap: 16px;
      }
      @media all and (max-width: 640px) {
        grid-template-columns: repeat(1,1fr);
      }
      &__item {
        background-color: #FBFBFB;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
        border-radius: 8px;
        padding: 16px;
      }
    }
  }
}

.awards {
  &__lead {
    margin-top: 25px;
  }
  &__certificates {
    margin-top: 25px;
    &__item {
      display: flex;
      margin-bottom: 15px;
      background-color: #FAFAFA;
      border-radius: 10px;
      padding: 15px;
      color: $primary;
      &__btn {
        text-decoration: none;
        margin-left: auto;
        background-color: $primary;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 0;
        height: 25px;
        display: flex;
        align-items: center;
        padding: 0 15px;
      }
    }
  }
}

.inter-paragraph {
  font-family: 'Inter', sans-serif;
}

.f-s-18 {
  font-size: 18px !important;
  line-height: 26px;
}
.spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(#fff, 0.5);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #ccc;
  border-bottom-color: #008C44;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 