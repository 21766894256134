.founder {
  display: flex;
  margin-top: 15px;
  align-items: center;
  &__media {
    background-color: #ccc;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background: url('../images/obrashenie.jpg') no-repeat 50% 50% / cover;
  }
  &__content {
    margin-left: 15px;
    font-size: 18px;
    &__name {
      font-weight: 500;
    }
  }
}