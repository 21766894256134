@import './variables';

.about-page {
  &__philosophy {
    background-color: $primary-light;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    margin-top: 50px;
    @media all and (max-width: 992px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    &__title {
      font-size: 40px;
      color: $primary;
      text-align: center;
      @media all and (max-width: 640px) {
        font-size: 26px;
      }
    }
    &__paragraph {
      max-width: 900px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @media all and (max-width: 640px) {
        font-size: 14px;
      }
    }
  }
  &__history {
    padding: 50px 0;
    @media all and (max-width: 992px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 50px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -9px;
        width: 18px;
        height: 18px;
        background-color: $primary;
        border-radius: 50%;
        top: 50%;
        margin-top: -9px;
      }
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 100%;
        top: 0;
        background-color: $primary;
      }
      @media all and (max-width: 768px) {
        &:before,
        &:after {
          display: none;
        }
      }
      &:first-child,
      &:last-child {
        &:before {
          height: 50%;
        }
      }
      &:first-child {
        &:before {
          top: initial;
          bottom: 0;
        }
      }
      &:last-child {
        &:before {
          top: 0;
        }
      }
      &:nth-child(2),
      &:nth-child(4) {
        flex-direction: row-reverse;
        .about-page__history__item__media {
          padding-left: 40px;
          padding-right: 0;
        }
        .about-page__history__item__text {
          padding-right: 40px;
          padding-left: 0;
        }
        @media all and (max-width: 768px) {
          flex-direction: column;
          .about-page__history__item__media {
            padding-left: 0;
          }
          .about-page__history__item__text {
            padding-right: 0;
          }
        }
      }
      @media all and (max-width: 768px) {
        flex-direction: column;
      }
      &__text {
        padding-left: 40px;
        padding-right: 0;
        font-size: 20px;
        line-height: 28px;
        @media all and (max-width: 1100px) {
          font-size: 16px;
          line-height: 26px;
        }
        @media all and (max-width: 768px) {
          padding-left: 0;
        }
      }
      &__media {
        padding-right: 40px;
        padding-left: 0;
        @media all and (max-width: 768px) {
          padding-right: 0;
          margin-bottom: 20px;
        }
      }
      &__media {
        width: 50%;
        min-width: 50%;
        display: flex;
        justify-content: center;
        @media all and (max-width: 768px) {
          min-width: 100%;
        }
        &__img {
          height: 400px;
          width: 400px;
          @media all and (max-width: 1100px) {
            width: 300px;
            height: 300px;
          }
          @media all and (max-width: 992px) {
            width: 250px;
            height: 250px;
          }
          @media all and (max-width: 768px) {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
    &__column {
      display: grid;
      gap: 60px;
      grid-template-columns: repeat(2,1fr);
      font-size: 18px;
      @media all and (max-width: 992px) {
        font-size: 16px;
        gap: 30px;
      }
      @media all and (max-width: 992px) {
        gap: 0;
        grid-template-columns: repeat(1,1fr);
      }
    }
  }
}