@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Light.eot');
  src: local('TT Firs Neue Light'), local('../fonts/TTFirsNeue-Light'),
      url('../fonts/TTFirsNeue-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-Light.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-Light.woff') format('woff'),
      url('../fonts/TTFirsNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Regular.eot');
  src: local('TT Firs Neue Regular'), local('../fonts/TTFirsNeue-Regular'),
      url('../fonts/TTFirsNeue-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-Regular.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-Regular.woff') format('woff'),
      url('../fonts/TTFirsNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-BoldItalic.eot');
  src: local('TT Firs Neue Bold Italic'), local('../fonts/TTFirsNeue-BoldItalic'),
      url('../fonts/TTFirsNeue-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-BoldItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-BoldItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-DemiBoldItalic.eot');
  src: local('TT Firs Neue DemiBold Italic'), local('../fonts/TTFirsNeue-DemiBoldItalic'),
      url('../fonts/TTFirsNeue-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-DemiBoldItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-DemiBoldItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-ExtraLight.eot');
  src: local('TT Firs Neue ExtraLight'), local('../fonts/TTFirsNeue-ExtraLight'),
      url('../fonts/TTFirsNeue-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-ExtraLight.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-ExtraLight.woff') format('woff'),
      url('../fonts/TTFirsNeue-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-ThinItalic.eot');
  src: local('TT Firs Neue Thin Italic'), local('../fonts/TTFirsNeue-ThinItalic'),
      url('../fonts/TTFirsNeue-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-ThinItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-ThinItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-MediumItalic.eot');
  src: local('TT Firs Neue Medium Italic'), local('../fonts/TTFirsNeue-MediumItalic'),
      url('../fonts/TTFirsNeue-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-MediumItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-MediumItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-DemiBold.eot');
  src: local('TT Firs Neue DemiBold'), local('../fonts/TTFirsNeue-DemiBold'),
      url('../fonts/TTFirsNeue-DemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-DemiBold.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-DemiBold.woff') format('woff'),
      url('../fonts/TTFirsNeue-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-BlackItalic.eot');
  src: local('TT Firs Neue Black Italic'), local('../fonts/TTFirsNeue-BlackItalic'),
      url('../fonts/TTFirsNeue-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-BlackItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-BlackItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-LightItalic.eot');
  src: local('TT Firs Neue Light Italic'), local('../fonts/TTFirsNeue-LightItalic'),
      url('../fonts/TTFirsNeue-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-LightItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-LightItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-ExtraLightItalic.eot');
  src: local('TT Firs Neue ExtraLight Italic'), local('../fonts/TTFirsNeue-ExtraLightItalic'),
      url('../fonts/TTFirsNeue-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-ExtraLightItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Bold.eot');
  src: local('TT Firs Neue Bold'), local('../fonts/TTFirsNeue-Bold'),
      url('../fonts/TTFirsNeue-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-Bold.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-Bold.woff') format('woff'),
      url('../fonts/TTFirsNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-ExtraBold.eot');
  src: local('TT Firs Neue ExtraBold'), local('../fonts/TTFirsNeue-ExtraBold'),
      url('../fonts/TTFirsNeue-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-ExtraBold.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-ExtraBold.woff') format('woff'),
      url('../fonts/TTFirsNeue-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Medium.eot');
  src: local('TT Firs Neue Medium'), local('../fonts/TTFirsNeue-Medium'),
      url('../fonts/TTFirsNeue-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-Medium.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-Medium.woff') format('woff'),
      url('../fonts/TTFirsNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Thin.eot');
  src: local('TT Firs Neue Thin'), local('../fonts/TTFirsNeue-Thin'),
      url('../fonts/TTFirsNeue-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-Thin.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-Thin.woff') format('woff'),
      url('../fonts/TTFirsNeue-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-ExtraBoldItalic.eot');
  src: local('TT Firs Neue ExtraBold Italic'), local('../fonts/TTFirsNeue-ExtraBoldItalic'),
      url('../fonts/TTFirsNeue-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-ExtraBoldItalic.woff') format('woff'),
      url('../fonts/TTFirsNeue-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Black.eot');
  src: local('TT Firs Neue Black'), local('../fonts/TTFirsNeue-Black'),
      url('../fonts/TTFirsNeue-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-Black.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-Black.woff') format('woff'),
      url('../fonts/TTFirsNeue-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Italic.eot');
  src: local('TT Firs Neue Italic'), local('../fonts/TTFirsNeue-Italic'),
      url('../fonts/TTFirsNeue-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/TTFirsNeue-Italic.woff2') format('woff2'),
      url('../fonts/TTFirsNeue-Italic.woff') format('woff'),
      url('../fonts/TTFirsNeue-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

