.header {
  background: #fff;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
  &.header--en {
    .logo {
      background: url('../images/logo-en.svg') no-repeat 0 50% / contain;
    }
  }
  &.header--kg {
    .logo {
      background: url('../images/logo-kg.svg') no-repeat 0 50% / contain;
    }
  }
  &.header--ru {
    .logo {
      background: url('../images/logo-ru.svg') no-repeat 0 50% / contain;
    }
  }
  &__locale {
    margin-left: 15px;

    @media all and (max-width: 992px) {
      margin-left: 0;
      margin-top: 15px;
    }

    .Dropdown-control {
      border-radius: 32px;
      border-color: $primary;
      padding-right: 30px;
    }
    .is-open .Dropdown-arrow {
      border-color: transparent transparent $primary;
    }
    .Dropdown-placeholder {
      color: $primary;
    }
    .Dropdown-arrow {
      border-color: $primary transparent transparent;
    }
    .Dropdown-menu {
      margin-top: 5px;
      border-color: $primary;
    }
    .Dropdown-option {
      color: $primary;
    }
  }
  .container {
    display: flex;
    align-items: center;
    height: 70px;
  }
  .logo {
    width:  180px;
    height: 58px;
  }
  &__navigation {
    margin-left: auto;
    display: flex;
    align-items: center;
    @media all and (max-width: 992px) {
      margin-left: 0;
      position: fixed;
      width: 260px;
      right: -100%;
      z-index: 2;
      background: #fff;
      height: 100%;
      top: 0;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 25px;
    }
    &__mobile-close {
      display: none;
      height: 70px;
      width: 100%;
      text-align: right;
      @media all and (max-width: 992px) {
        color: $primary;
        cursor: pointer;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        right: 15px;
      }
    }
    &.top.header__navigation--toggled {
      @media all and (max-width: 992px) {
        right: 0;
        ~ .header__navigation__mobile-overlay {
          height: 100%;
          position: fixed;
          width: 100%;
          z-index: 1;
          background: rgba(#000, 0.5);
          right: 0;
          top: 0;
        }
      }
      
    }
  }
  &__menu {
    display: flex;
    @media all and (max-width: 992px) {
      flex-direction: column; 
    }
    &__item-link {
      color: $primary;
      text-decoration: none;
      font-size: 16px;
      margin-right: 24px;
      text-transform: capitalize;
      @media all and (max-width: 1100px) {
        margin-right: 12px;
      }
      @media all and (max-width: 992px) {
        margin-bottom: 10px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__socials {
    display: flex;
    @media all and (max-width: 992px) {
      margin-top: 10px;
    }
    &__item-link {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      &--instagram {
        background: url('../images/instagram.png') no-repeat 50% 50% / cover;
      }
      &--whatsapp {
        background: url('../images/whatsapp.png') no-repeat 50% 50% / cover;
      }
      &--facebook {
        background: url('../images/facebook.png') no-repeat 50% 50% / cover;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__burger {
    cursor: pointer;
    display: none;
    height: 16px;
    width: 20px;
    justify-content: space-between;
    flex-direction: column;
    @media all and (max-width: 992px) {
      display: flex;
      margin-left: auto;
    }
    &__item {
      height: 2px;
      width: 100%;
      background: $primary;
    }
  }
}