.footer {
  padding-bottom: 24px;
  padding-top: 24px;
  .header {
    position: static;
    @media all and (max-width: 992px) {
      .container {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    box-shadow: none;
  }
  .header__navigation__mobile-close,
  .header__navigation__mobile-overlay,
  .header__burger {
    display: none;
  }
  @media all and (max-width: 992px) {
    .header__navigation {
      position: static;
      width: 100%;
      padding-left: 0;
    }
    .header__socials,
    .header__menu {
      flex-direction: row;
    }
    .header__menu {
      margin-top: 10px; 
      flex-wrap: wrap;
    }
    .header__socials {
      margin-top: 0;
    }
  }
}

.copyright {
  border-top: 1px solid $primary-light;
  padding: 24px 0;
  .container {
    text-align: center;
  }
}

.contact {
  background-color: $primary;
  padding: 100px 0;
  @media all and (max-width: 992px) {
    padding: 50px 0;
  }
  @media all and (max-width: 768px) {
    padding: 25px 0;
  }
  .container {
    display: grid;
    gap: 64px;
    grid-template-columns: repeat(2,1fr);
    @media all and (max-width: 992px) {
      gap: 16px;
    }
    @media all and (max-width: 768px) {
      display: block;
    }
  }
  &__section {
    flex-basis: 50%;
    @media all and (max-width: 768px) {
      &:last-child {
        margin-top: 25px;
      }
    }
    &__title {
      font-size: 48px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 32px;
      @media all and (max-width: 992px) {
        font-size: 32px;
        margin-bottom: 16px;
      }
      &--form {
        color: #FDCB19;
      }
    }
  }
  &__content {
    &__text {
      color: #fff;
      font-weight: 300;
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 120%;
      @media all and (max-width: 768px) {
        font-size: 18px;
      }
    }
    &__item {
      display: flex;
      color: #fff;
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 120%;
      align-items: center;
      text-decoration: none;
      @media all and (max-width: 768px) {
        font-size: 18px;
      }
      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        &--phone {
          background: url('../images/phone.svg') no-repeat 50% 50% / cover;
        }
        &--mail {
          background: url('../images/mail.svg') no-repeat 50% 50% / cover;
        }
        &--marker {
          background: url('../images/marker.svg') no-repeat 50% 50% / cover;
        }
      }
    }
  }
}

.contact-form {
  &__row {
    margin-bottom: 15px;
  }
  &__label {
    color: #fff;
    font-weight: 300;
    margin-bottom: 3px;
    text-transform: capitalize;
  }
  &__control {
    height: 40px;
    border-radius: 6px;
    border: 1px solid #fff;
    width: 100%;
    outline: none !important;
    padding: 0 15px;
    &--textarea {
      height: 160px;
      padding: 10px;
    }
  }
  &__btn {
    height: 40px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #fff;
    outline: none !important;
    width: 160px;
    font-weight: 00;
    font-size: 16px;
    color: $primary; 
    cursor: pointer;
  }
}