@import './variables';

.home {
  &__section {
    padding: 100px 0;
    @media all and (max-width: 992px) {
      padding: 50px 0;
    }
    @media all and (max-width: 768px) {
      padding: 25px 0;
    }
    &__title {
      color: $primary;
      text-align: center;
      font-size: 48px;
      font-weight: 500;
      @media all and (max-width: 992px) {
        font-size: 36px;
      }
    }
  }
  &__learn-more {
    background-color: #FFDE17;
    border: 1px solid #FFDE17;
    border-radius: 30px;
    padding: 0 20px;
    height: 64px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    font-weight: 300;
    max-width: 240px;
    text-decoration: none;
    &__icon {
      margin-left: 10px;
      width: 24px;
      height: 20px;
      background: url('../images/arrow-right.svg') no-repeat 50% 50% / cover;;
    }
    @media all and (max-width: 640px) {
      height: 48px !important;
    }
  }
  .news {
    background-color: $primary-light;
    padding: 100px 0;
    @media all and (max-width: 992px) {
      padding: 50px 0;
    }
    @media all and (max-width: 768px) {
      padding: 25px 0;
    }
    &__title {
      color: $primary;
    }
    &__instagram {
      text-decoration: none;
      color: #000;
      margin-top: 35px;
      display: flex;
      justify-content: center;
      &__wrapper {
        display: flex;
        align-items: center;
      }
      &__media {
        margin-right: 25px;
        width: 106px;
        height: 106px;
        border-radius: 50%;
        background: url('../images/news_instagram_media.jpg') no-repeat 50% 50% / cover;
      }
      &__content {
        &__name {
          font-weight: 500;
          font-size: 18px;
        }
        &__followers {
          font-weight: 300;
        }
      }
    }
    &__list {
      margin-top: 50px;
      display: grid;
      gap: 36px;
      grid-template-columns: repeat(3,1fr);
      @media all and (max-width: 992px) {
        display: block;
      }
    }
    &__item {
      @media all and (max-width: 992px) {
        display: flex;
        margin-bottom: 25px;
      }
      @media all and (max-width: 570px) {
        flex-direction: column;
        align-items: center;
      }
      &__media {
        height: 380px;
        width: 100%;
        overflow: hidden;
        border-radius: 8px;
        @media all and (max-width: 992px) {
          height: 200px;
          min-width: 200px;
          width: 200px;
        }
        img {
          width: 100%;
        }
      }
      &__text {
        margin-top: 20px;
        font-size: 18px;
        line-height: 120%;
        @media all and (max-width: 992px) and (min-width: 570px) {
          margin-top: 0;
          margin-left: 25px;
        }
        @media all and (max-width: 570px) {
          text-align: center;
        }
      }
    }
  }

  .products {
    background-color: #FAFAFA;
    &__list {
      margin-top: 50px;
      display: grid;
      gap: 32px;
      grid-template-columns: repeat(4,1fr);
      @media all and (max-width: 1100px) {
        grid-template-columns: repeat(3,1fr);
        gap: 16px;
      }
      @media all and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
      }
      @media all and (max-width: 640px) {
        grid-template-columns: repeat(1,1fr);
      }
    }
    &__item {
      background-color: #fff;
      border-radius: 20px;
      height: 300px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &__media {
        text-align: center;
        img {
          max-width: 100%;
        }
      }
      &__title {
        margin-top: 15px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
      }
    }
    &__download {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media all and (max-width: 768px) {
        margin-top: 35px;
      }
      &__btn {
        text-decoration: none;
        width: 340px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        border-radius: 30px;
        border: 1px solid $primary;
        height: 60px;
        &__text {
          color: #fff;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 500;
        }
        &__icon {
          margin-left: 10px;
          width: 38px;
          height: 38px;
          background: url('../images/catalogue.svg') no-repeat 50% 50% / cover;
        }
      }
      &__text {
        margin-top: 35px;
        color: $primary;
        font-size: 22px;
        line-height: 120%;
        @media all and (max-width: 768px) {
          margin-top: 15px;
        }
      }
    }
  }
  .companies {
    .home__section__title {
      text-align: left;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media all and (max-width: 768px) {
        flex-direction: column;
      }
      @media all and (max-width: 640px) {
        align-items: flex-start;
      }
    }
    &__list {
      grid-template-columns: repeat(3,1fr);
      @media all and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
      }
      @media all and (max-width: 640px) {
        grid-template-columns: repeat(1,1fr);
      }
    }
    .companies__item__description {
      display: none;
    }
  }
  
  .history {
    background-color: $primary-light;
    .show-mobile {
      display: none;
      @media all and (max-width: 640px) {
        display: block;
        .history__content__media__img {
          max-width: 60%;
        }
      }
    }
    .container {
      display: flex;
    }
    &__media {
      @media all and (max-width: 640px) {
        display: none;
      }
      width: 50%;
      min-width: 50%;
      &__img {
        max-width: 100%;
      }
    }
    &__content {
      padding-left: 40px;
      @media all and (max-width: 640px) {
        padding-left: 0;
      }
      &__text {
        font-size: 40px;
        line-height: 120%;
        color: $primary;
        font-weight: 300;
        @media all and (max-width: 992px) {
          font-size: 32px;
        }
        strong {
          font-weight: 600;
        }
      }
      .home__learn-more {
        margin-top: 35px;
        height: 64px;
        text-decoration: none;
        @media all and (max-width: 640px) {
          margin-top: 15px;
        }
      }
      &__media {
        @media all and (max-width: 992px) {
          display: none;
        }
      }
    }
  }
  
  .hero {
    background-color: $primary;
    &__media {
      overflow: hidden;
      &__img {
        width: 100%;
        @media all and (max-width: 768px) {
          height: calc(100vh - 310px);
          width: auto;
        }
      }
    }
    &__content {
      padding-top: 80px;
      padding-bottom: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media all and (max-width: 992px) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      @media all and (max-width: 768px) {
        flex-direction: column;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      &__title {
        font-size: 72px;
        margin: 0;
        color: #fff;
        font-weight: 500;
        @media all and (max-width: 768px) {
          font-size: 36px;
          text-align: center;
        }
      }
      &__download {
        color: $primary;
        background-color: #FFDF18;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: 500;
        text-decoration: none;
        @media all and (max-width: 768px) {
          margin-top: 10px;
          width: 120px;
          height: 120px;
        }
        &__text {
          text-align: center;
          font-size: 20px;
          text-transform: uppercase;
          line-height: 120%;
          @media all and (max-width: 768px) {
            font-size: 15px;
          }
        }
        &__icon {
          width: 40px;
          height: 40px;
          background: url('../images/download-green.svg') no-repeat 50% 50% / cover;
        }
      }
    }
  }

  .points {
    background-color: #CCEAFF;
    &__title {
      color: #FF5C00;
      font-weight: 500;
      font-size: 48px;
    }
    &__lead {
      color: #264F6C;
      font-size: 22px;
      line-height: 120%;
    }
    &__map {
      height: 550px;
      margin: 25px 0;
      @media all and (max-width: 768px) {
        height: 350px;
      }
    }
  }
  
  .lines {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    &__list {
      display: flex;
      overflow: hidden;
      // justify-content: center;
    }
    &__item {
      display: flex;
      height: 50px;
      align-items: center;
      margin-right: 20px;
      &__media {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }
      &__text {
        white-space: nowrap;
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }
}
